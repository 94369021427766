.stand-about {
  display: grid;
  row-gap: 1.5em;

  grid-template-columns: 1fr auto;
  column-gap: 1em;

  &__title {
    @include h24;

    grid-column: 1;
    grid-row: 1;

    @include media-tablet {
      @include h36; } }

  &__content {
    @include p14;

    grid-column: 1 / span 2;

    @include media-tablet {
      @include p18; }

    p {
      margin: 1.4286em 0;

      &:first-child {
        margin-top: 0; }

      &:last-child {
        margin-bottom: 0; } } }

  &__directions {
    display: grid;
    row-gap: 1em;
    grid-column: 1 / span 2;

    &-title {
      @include fs18;

      font-weight: 800; }

    &-list {
      @include p14;

      color: $grey-88;

      @include media-tablet-h {
        @include p16; } } }

  &__change-link {
    @include fs11;

    grid-row: 1;
    grid-column: 2;

    align-self: center;

    a {
      color: $red; } } }
