.position {
  padding-bottom: 5em;
  background: $grey-f0;
  color: $color-black;

  @include media-tablet {
    padding-top: 1.5em;
    padding-bottom: 8.125em; }

  hr {
    margin: 0 0 2.5em;
    height: 1px;
    border: none;
    background: #808090;

    @include media-tablet {
      margin-bottom: 5em; } }

  &__main {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;
    align-items: center;

    @include media-tablet {
      row-gap: 4em;
      align-items: stretch; }

    @include media-tablet-h {
      flex-direction: row;
      column-gap: 5em;
      align-items: center; }

    @include media-desktop-1440 {
      justify-content: space-between; } }

  &__left {
    @include media-desktop-1440 {
      flex: 0 0 45.625em; } }

  &__title {
    @include h36;
    margin-bottom: 1.125rem;

    @include media-tablet {
      @include h48;
      margin-bottom: 1.5rem; }

    @include media-tablet-h {
      margin-bottom: 1.625rem; }

    span {
      display: none;

      @include media-tablet {
        display: inline-block; } } }

  &__desc {
    @include fs14;

    @include media-tablet {
      @include fs18; }

    @include media-desktop {
      @include fs24; } }

  &__right {
    @include media-tablet-h {
      flex: 0 0 26.5em; }

    @include media-desktop {
      flex-basis: 29.0625em; } }

  &__reward {
    position: relative;
    width: 17.5em;
    padding: 1.25em 1.25em 4.0625em;
    border-radius: 1.5em;
    background: #fff;
    overflow: hidden;

    @include media-tablet {
      padding: 1.5em;
      width: auto; }

    &-title {
      @include fs18;
      display: flex;
      align-items: center;
      column-gap: 0.875rem;
      margin-bottom: 0.75rem;
      font-weight: 700;

      @include media-tablet {
        @include h24;
        margin-bottom: 0.875rem; }

      &::before {
        content: '';
        width: 1.0625rem;
        height: 1.375rem;
        background: url(@public/images/position-reward.svg) no-repeat; } }

    &-text {
      @include fs14;
      width: 13.25rem;

      @include media-tablet {
        @include fs18;
        width: 18.25rem; } }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 12.3125em;
      height: 2.875em;
      background: url(@public/images/position-reward-bg-mobile-1.png) no-repeat;
      z-index: 2;

      @include media-tablet {
        background: url(@public/images/position-reward-bg-tablet-1.png) no-repeat;
        width: 9.3125em;
        height: 6.4375em;
        right: 0;
        left: auto; }

      @include media-tablet-h {
        right: -2.5625em; }

      @include media-desktop {
        right: 0; } }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 9.1875em;
      height: 3.3125em;
      background: url(@public/images/position-reward-bg-mobile-2.png) no-repeat;
      z-index: 1;

      @include media-tablet {
        background: url(@public/images/position-reward-bg-tablet-2.png) no-repeat;
        width: 8.625em;
        height: 6.5em;
        top: 0; }

      @include media-tablet-h {
        right: -2.5625em; }

      @include media-desktop {
        right: 0; } } } }
