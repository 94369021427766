.about-netw {
  background: $grey-f0;

  @include media-desktop-1600 {
    height: 37.4375em; }

  &__main {
    @include media-tablet-h {
      display: flex;
      justify-content: space-between;
      align-items: center; }

    @include media-desktop-1600 {
      position: relative;
      height: 100%; } }

  &__cont {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;
    padding-top: 4em;
    padding-bottom: 5em;

    @include media-tablet {
      align-items: flex-start;
      padding-bottom: 4em; }

    @include media-tablet-h {
      row-gap: 5em;
      margin-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0; }

    @include media-desktop-1600 {
      margin-left: auto; } }

  &__title {
    @include h36;

    @include media-tablet {
      @include h48; } }

  &__stats {
    @include media-tablet {
      display: flex;
      column-gap: 1.5em; }

    @include media-tablet-h {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 5em; }

    @include media-desktop-1440 {
      grid-template-columns: repeat(4, auto); }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-tablet {
        flex-direction: column;
        align-items: flex-start; }

      &:not(:last-child) {
        margin-bottom: 0.75rem;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid #888899;

        @include media-tablet {
          margin-bottom: 0;
          padding-right: 1.5em;
          padding-bottom: 0;
          border-right: 1px solid #888899;
          border-bottom: none; }

        @include media-tablet-h {
          padding-right: 0;
          border-right: none; }

        @include media-desktop-1440 {
          padding-right: 1.5em;
          border-right: 1px solid #888899; } }

      &:nth-child(odd) {
        @include media-tablet-h {
          padding-right: 1.5em;
          border-right: 1px solid #888899; } } }

    &-number {
      @include h24;
      display: flex;
      align-items: center;
      column-gap: 0.75rem;
      color: $color-black;

      @include media-tablet {
        @include h36; }

      @include media-tablet-h {
        @include h48; }

      span {
        padding: 0 0.3125rem;
        font-size: 0.875rem;
        line-height: 1.3;
        font-weight: 800;
        color: #888899;
        border: 1px solid #888899;
        border-radius: 2.4375rem;

        @include media-tablet {
          margin-top: -1em;
          padding: 0 0.40625rem;
          font-size: 1rem; } } }

    &-text {
      @include fs14; } }

  .btn {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    font-weight: 700;

    @include media-tablet {
      width: 22.625rem; }

    @include media-tablet-h {
      @include h24;
      width: 26.4375rem;
      height: 3.875rem; } } }

.forum-news {
  max-height: 37.8125em;
  padding: 1.25em 0.625em 1.25em 1.25em;
  color: $color-black;
  background: #fff;
  border-radius: 0 0 1.5em 1.5em;
  overflow: hidden;

  @include media-tablet {
    max-height: 31.4375em;
    padding: 2em 1.875em 1.875em; }

  @include media-tablet-h {
    flex: 0 0 20.9375em;
    height: 37.4375em;
    padding: 1.5em 1.875em 1.5em 1.5em;
    border-radius: 1.5em 0 0 1.5em; }

  @include media-desktop {
    flex-basis: 33.75em;
    padding: 2.5em; }

  @include media-desktop-1600 {
    position: absolute;
    top: 0;
    right: 0;
    width: 45em; }

  &__title {
    @include h24;
    margin-bottom: 1.25rem;

    @include media-tablet {
      margin-bottom: 1.5rem; } }

  &__list {
    max-height: 33.4375em;
    padding-right: 0.625em;
    // overflow-y: scroll
    overflow-y: auto;
    scrollbar-color: rgba(#191919, 0.15) rgba(#191919, 0.05);
    scrollbar-width: thin;

    @include media-tablet {
      max-height: 25.6875em;
      padding-right: 1.875em; }

    @include media-tablet-h {
      height: 32.5em;
      padding-right: 3em; }

    @include media-desktop {
      height: 31.5625em;
      padding-right: 4em; }

    @include media-desktop-1600 {
      padding-right: 15.1875em; }

    &::-webkit-scrollbar {
      width: 0.25rem;
      margin-right: 5px; }

    &::-webkit-scrollbar-track {
      background-color: rgba(#191919, 0.05); }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(#191919, 0.15);
      border-radius: 1.25rem; } }

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 0.75em;
    margin-bottom: 1.25em;
    padding-bottom: 1.25em;

    @include media-tablet {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; }

    &:not(:last-child) {
      border-bottom: 1px solid #d9d9de; }

    &-title {
      @include fs14;
      font-weight: 800; }

    &-date-block {
      display: flex;
      align-items: center;
      column-gap: 0.75em;

      hr {
        height: 0.875em;
        width: 1px;
        margin: 0;
        background: #000222;
        border: none; } }

    &-time {
      position: relative;
      @include fs14;

      span {
        position: relative;
        top: -0.4em;
        font-size: 0.6875em;
        line-height: 1.35; } }

    &-date {
      @include fs14; }

    &-text {
      @include fs14;

      @include media-tablet {
        flex-basis: 100%; } } } }
