.prizes {
  padding-top: 3.5rem;
  padding-bottom: 0;
  background-color: $color-black;
  position: relative;

  @include media-tablet {
    padding-top: 4.625rem;
    padding-bottom: 1.25rem;
    overflow: hidden; }

  @include media-tablet-h {
    padding-top: 7rem;
    padding-bottom: 2.375rem; }

  @include media-desktop {
    padding-top: 5em;
    padding-bottom: 0.5rem; }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 19.5em;
    background: $color-yellow-new;
    border-bottom-left-radius: 11em 9em;
    border-bottom-right-radius: 29em 27em;
    left: 0;
    top: 0;

    @include media-tablet {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      left: 50%;
      margin-left: -5em;
      background-size: cover;
      width: 71.25em;
      height: 45.1em;
      margin-left: -12em;
      background: url(@public/images/prizes-bg.svg) no-repeat center;
      background-size: contain; }

    @include media-tablet-h {
      margin-left: -6em; } }

  &__header {
    .cont {
      display: grid;
      row-gap: 1.5em;

      @include media-tablet {
        grid-template-columns: repeat(2, 1fr);
        align-items: center; } } }

  &__title {
    @include reset;
    @include h36;
    color: $color-black;
    position: relative;
    z-index: 2;

    @include media-tablet {
      color: #ffffff;
      @include h48;
      max-width: 5em; }

    @include media-tablet-h {
      max-width: initial; }

    @include media-desktop {
      @include h64; } }

  &__desc {
    position: relative;
    z-index: 2;
    color: $color-black;
    text-decoration: underline;
    padding-right: 3em;

    @include media-tablet {
      @include fs18;
      padding: 0 2rem;
      padding-left: 2em; }

    @include media-tablet-h {
      padding: 0 0 0 8.1875rem;
      font-weight: 400; }

    @include media-desktop {
      max-width: 24.75em;
      padding: 0;
      margin-left: auto; } }

  &__body {
    .cont {
      @include max-w(768px) {
        width: 100%; } } }

  .swiper-container {
    padding: 2.5rem 0;

    @include media-tablet {
      position: relative;
      padding: 3.75rem 0; }

    @include media-tablet-h {
      padding: 3.125rem 0; }

    @include media-desktop {
      padding: 5rem 0; } }

  .swiper-pagination {
    @include media-tablet {
      display: none; } }

  .swiper-pagination-bullet {
    width: 0.75em;
    height: 0.75em;
    margin: 0 0.75em;
    background-color: $grey-e5;
    opacity: 1; }

  .swiper-pagination-bullet-active {
    background-color: $grey-89; }

  &__slider {
    &-wrapper {
      position: relative;
      width: 100%;
      min-width: 1px;

      .swiper-slide {
        height: 100% !important; } }

    &-nav {
      position: absolute;
      top: 47.2%;
      z-index: 50;
      display: none;
      width: 2.9375em;
      height: 3.5em;
      background-color: $grey-80;
      border-radius: 2rem 0 0 2rem;
      background-image: url(@public/images/prizes-slider-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      @include media-tablet {
        display: block; }

      &--prev {
        left: 0;
        transform: translateY(-50%) rotate(180deg); }

      &--next {
        right: 0;
        transform: translateY(-50%); }

      &--disabled {
        display: none; } } }

  &__slide {
    display: grid;
    grid-template-rows: 12.5625em 1fr;
    box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border-radius: 0.75em;
    overflow: hidden;

    &-content {
      display: grid;
      row-gap: 1.875em;
      align-items: start;
      grid-template-rows: 1fr;
      padding: 1.875em 1.25em; }

    &-stand {
      @include fs16;
      color: $grey-89;
      font-weight: bold;
      margin: 0; }

    &-bg {
      border-radius: 0.75em;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; } }

    &-title {
      @include reset;
      @include fs18;
      font-weight: 800;

      a {
        color: $blue;
        text-decoration: underline; } }

    &-link {
      color: $red;
      font-weight: 800;
      align-self: end; } } }
