@keyframes ping-pong {
  50% {
    transform: translateY(50%); }

  100% {
    transform: translateY(0); } }

@keyframes header {
  0% {
    opacity: 0; }

  16.66% {
    opacity: 1; }

  27.77% {
    opacity: 0; } }

@keyframes preloader {
  10% {
    transform: scale(2);
    background: $red;
    opacity: 1; } }

@include media-tablet-h {
  @keyframes slider-before {
    50% {
      transform: rotate(-25deg);
      right: -16.7em; }

    100% {
      transform: rotate(0);
      right: -20.7em; } }

  @keyframes slider-after {
    50% {
      transform: rotate(-13deg);
      right: -68.6em;
      bottom: -9.25em; }

    100% {
      transform: rotate(0);
      right: -75.6em;
      bottom: -27.25em; } } }

@include media-desktop {
  @keyframes slider-before {
    50% {
      transform: rotate(-25deg);
      right: -10.7em; }

    100% {
      transform: rotate(0);
      right: -17.7em; } }

  @keyframes slider-after {
    50% {
      transform: rotate(-4deg);
      right: -66em;
      bottom: -12.25em; }

    100% {
      transform: rotate(0);
      right: -73em;
      bottom: -27.25em; } } }

@include media-desktop-1440 {
  @keyframes slider-before {
    50% {
      transform: rotate(-25deg);
      right: -8.7em; }

    100% {
      transform: rotate(0);
      right: -14.7em; } }

  @keyframes slider-after {
    50% {
      transform: rotate(-5deg);
      right: -62em;
      bottom: -11.25em; }

    100% {
      transform: rotate(0);
      right: -70em;
      bottom: -19.25em; } } }

@include media-desktop-1600 {
  @keyframes slider-before {
    50% {
      transform: rotate(-25deg);
      right: -9.5em; }

    100% {
      transform: rotate(0);
      right: -14.7em; } }

  @keyframes slider-after {
    50% {
      bottom: -11.8em;
      right: -64em; }

    100% {
      right: -70em;
      bottom: -19.25em; } } }
