.program {
  position: relative;

  padding: 2.625em 0 5em;

  background: $color-black;

  @include media-tablet {
    padding: 5em 0 7.5em; }

  .cont {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -2.625em;
      left: -1.25em;

      width: 51.375em;
      height: 22.125em;

      background: url(@public/images/program-bg.svg) no-repeat;

      z-index: 1;

      @include media-tablet {
        top: -5em; } } }

  &__header {
    position: relative;

    margin-bottom: 2.5em;

    z-index: 2;

    @include media-tablet {
      margin-bottom: 4em; } }

  &__title {
    @include h36;

    color: $color-black;

    @include media-tablet {
      @include h48; } }

  &__main {
    position: relative;
    z-index: 2; }

  &__list {
    @include lreset;

    display: flex;
    flex-direction: column;
    row-gap: 1.5em;

    @include media-tablet {
      flex-direction: row;
      column-gap: 2em; } }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 1.25em;

    background: #fff;
    border-radius: 24px;

    @include media-tablet {
      flex: 1;
      padding: 1.5em; }

    @include media-tablet-h {
      padding: 2.5em; }

    &-title {
      @include h24;

      margin-bottom: 2.5rem;

      @include media-tablet {
        @include h36;

        margin-bottom: 2.5rem; } }

    &-desc {
      @include fs18;

      margin-bottom: 0.75rem;

      font-weight: 700; }

    &-text {
      @include reset;
      @include p14;

      display: flex;
      flex-direction: column;
      row-gap: 0.75rem;
      margin-bottom: 2rem;

      @include media-tablet {
        @include fs18; }

      p {
        @include reset; } }

    .btn {
      font-weight: 400;

      @include media-tablet {
        margin-top: auto; } }

    &-invest {
      color: #fff;

      background: #232226;
      border: 1px solid #fff;

      .program__item {
        &-title {
          margin-bottom: 0.375rem;

          span {
            color: #F0A501; } }

        &-date {
          @include fs18;

          margin-bottom: 2.5rem;

          font-weight: 700; }

        &-desc {}
        &-text {} }
      .btn {
        color: #fff;

        background: #F0A501;

        &:hover {
          color: $grey-88;

          background-color: $grey-f5; } } } }

  // &__days
  //   display: flex
  //   flex-direction: column
  //   row-gap: 2.5em

  //   @include media-tablet
  //     row-gap: 4em

  //   hr
  //     width: 100%
  //     height: 1px
  //     margin: 0
  //     border: none
  //     background: #888899

  // &__day
  //   display: flex
  //   flex-direction: column
  //   row-gap: 1.5em
  //   color: #fff

  //   @include media-tablet
  //     flex-direction: row
  //     flex-wrap: wrap
  //     column-gap: 2.5em

  //   @include media-tablet-h
  //     justify-content: space-between

  //   @include media-desktop-1440
  //     align-items: center

  //   @include media-desktop-1600
  //     column-gap: 4em

  //   &-title
  //     +h24

  //     @include media-tablet
  //       flex: 1 1 100%
  //       +h36

  //     @include media-desktop-1440
  //       flex: 0 1 22.5rem

  //   &-content
  //     @include media-tablet
  //       flex: 1

  //     @include media-tablet-h
  //       flex: 0 1 28.875rem

  //     @include media-desktop
  //       flex: 0 1 38.875rem

  //     @include media-desktop-1440
  //       flex: 1

  //   &-desc
  //     +fs18
  //     margin-bottom: 0.75rem
  //     font-weight: 700

  //   &-text
  //     +reset
  //     +p14

  //     @include media-tablet
  //       +fs14

  //   .btn
  //     font-weight: 400

  //     @include media-tablet
  //       flex: 0 1 18.125rem

  //     @include media-tablet-h
 }  //       flex: 0 1 23.625rem
