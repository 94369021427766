.cabinet-events {
  display: grid;
  row-gap: 2rem;

  &__title {
    @include reset;

    font-weight: 800; }

  &__header {
    display: grid;
    grid-template-columns: repeat(3, max-content) 1fr;
    column-gap: 2.5rem; }

  &__today {
    &-day {
      display: flex;
      height: 2.8125rem;
      align-items: center;
      padding: 0 1rem;

      color: $grey-88;

      background-color: #ffffff;
      border-radius: 2rem;
      border: 1px solid $grey-88; }

    &-time {
      display: flex;
      height: 2.8125rem;
      align-items: center;
      padding: 0 1rem;

      color: $grey-88;

      span {
        font-weight: 800;
        padding-right: 0.75rem; } } }


  &__calendar-btn {
    @include fs14;

    height: 2.8125rem;
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0 1.25rem;

    color: #ffffff;
    font-weight: 800;

    background-color: $blue;
    border-radius: 2rem;

    svg {
      margin-right: 0.75rem; } }

  &__body {}

  &__tabs {
    &-header {
      display: flex; }

    &-body {
      display: grid;
      row-gap: 2rem; }

    &-btn {
      @include reset;
      @include fs14;

      padding: 0.5rem 1.25rem;
      min-width: 10.375rem;

      color: $grey-88;

      background-color: $grey-ee;
      border-radius: 1.25rem 1.25rem 0 0;
      border: none;

      span {
        padding: 0.125rem 0.25rem;
        margin-left: 0.5rem;

        font-size: 0.7857em;
        color: #ffffff;
        font-weight: 800;

        background-color: $blue;
        border-radius: 2rem; }

      &--active {
        font-weight: 800;

        color: $dark-grey;

        background-color: #ffffff; }


      &--event-create {
        margin-left: auto;

        color: $blue;

        background-color: #ffffff;

        svg {
          width: 0.625rem;
          height: 0.625rem;

          margin-left: 1rem; } } }

    &-block {
      padding: 2.5rem;
      display: grid;
      row-gap: 2rem;

      border-radius: 1.25rem;
      background-color: #ffffff;

      &:first-child {
        border-radius: 0 0 1.25rem 1.25rem; }

      &--bordeblue {
        padding: 1.25rem; }

      &-wrapper {
        display: grid;
        row-gap: 2rem;
        padding: 1.25rem;

        border-radius: 0.75rem;
        border: 1px solid $blue; }


      &>hr {
        margin: 0 -2.5rem;
        height: 1px;

        border: none;
        background-color: $grey-e5; } }

    &__empty-text {
      a {
        color: $blue;
        text-decoration: underline;

        &:hover {
          text-decoration: none; } } } } }
