.header--index {
  display: block;
  height: auto;
  background: $color-black;
  z-index: 0;

  .header {
    &__main {
      padding: 3.8125em 1.25em 0;

      @include media-tablet {
        padding: 4.625em 1.875em 0; }

      @include media-tablet-h {
        display: grid;
        grid-template-columns: 25.75em 21.475em;
        justify-content: space-between;
        align-items: center;
        padding: 5em 1.25em; }

      @include media-desktop {
        padding: 5em;
        grid-template-columns: 28em 23.625em; }

      @include media-desktop-1440 {
        grid-template-columns: 33.75em 23.625em; }

      @include media-desktop-1600 {
        width: 100em;
        margin: 0 auto;
        padding: 8.125em 5em 8.875em; }

      &-left {
        @include media-tablet {
          display: flex;
          align-items: center; }

        @include media-tablet-h {
          align-items: flex-start;
          flex-direction: column; }

        &-top {
          margin-bottom: 2em;

          @include media-tablet {
            margin-bottom: 0; }

          @include media-tablet-h {
            margin-bottom: 2.9375em; } }

        &-date {
          @include fs18;
          margin-bottom: 0.25rem;
          font-weight: 700;
          color: $color-yellow-new;

          @include media-tablet {
            margin-bottom: 0; }

          @include media-desktop {
            @include h24; } }

        &-logo {
          margin-bottom: 0.25rem;

          @include media-tablet {
            margin-bottom: 1.25em; }

          img {
            display: block;
            width: 17.5em;

            @include media-tablet {
              width: 20em; }

            @include media-tablet-h {
              width: 100%; } } }

        &-text {
          @include h36;
          text-transform: uppercase;
          color: #fff;

          @include media-tablet {
            @include h48; } }

        &-bottom {
          @include media-tablet {
            margin-left: auto; }

          @include media-tablet-h {
            margin: 0;
            width: 100%; } }

        &-reg {
          .btn {
            @include h24;
            width: 17.5rem;
            min-width: auto;
            height: 3.875rem;

            @include media-tablet {
              width: 16.75rem; }

            @include media-tablet-h {
              width: 100%; } } } }

      &-right {
        position: relative;
        height: 10.8125em;

        @include media-tablet {
          height: 25.25em; }

        @include media-tablet-h {
          height: 100%; } } }

    &-slider {
      position: relative;
      height: 100%;

      @include media-tablet {
        width: 23.75em;
        padding-top: 8.875em; }

      @include media-tablet-h {
        width: 100%;
        padding: 0; }

      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        right: -8%;
        width: 28.375em;
        height: 8.375em;
        background: url(@public/images/header-slider-before-mobile.png) no-repeat;
        z-index: 1;
        animation-name: slider-before;
        animation-duration: 16s;
        animation-iteration-count: infinite;

        @include media-tablet {
          width: 89.5em;
          height: 22.4375em;
          left: -16.5625em;
          background: url(@public/images/header-slider-before-tablet.png) no-repeat; }

        @include media-tablet-h {
          width: 56.125em;
          height: 101.5em;
          left: auto;
          right: -20.7em;
          bottom: -40.625em;
          background: url(@public/images/header-slider-before-desktop.svg) no-repeat; }

        @include media-desktop {
          right: -17.7em; }

        @include media-desktop-1440 {
          right: -14.7em;
          bottom: -34.625em; }

        @include media-desktop-1600 {
          bottom: -33.625em;
          transform: rotate(0); } }

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        right: -20%;
        width: 28.125em;
        height: 8.1875em;
        background: url(@public/images/header-slider-after-mobile.png) no-repeat;
        z-index: 2;
        animation-name: slider-after;
        animation-duration: 16s;
        animation-iteration-count: infinite;

        @include media-tablet {
          width: 82.375em;
          height: 21.4375em;
          left: -37.9375em;
          background: url(@public/images/header-slider-after-tablet.png) no-repeat; }

        @include media-tablet-h {
          width: 101.5em;
          height: 101.5em;
          left: auto;
          right: -75.6em;
          bottom: -27.25em;
          background: url(@public/images/header-slider-after.svg) no-repeat; }

        @include media-desktop {
          right: -73em; }

        @include media-desktop-1440 {
          right: -70em;
          bottom: -19.25em; }

        @include media-desktop-1600 {
          bottom: -19.8em; } }

      &__item {
        position: absolute;
        left: 0;
        top: 60%;
        transform: translateY(-50%);
        display: none;
        color: $color-black;
        z-index: 10;
        opacity: 0;
        animation-name: header;
        animation-duration: 16s;
        animation-iteration-count: infinite;

        @include media-tablet-h {
          top: 50%; }

        &:nth-child(1) {
          animation-delay: 0; }

        &:nth-child(2) {
          animation-delay: 4s; }

        &:nth-child(3) {
          animation-delay: 8s; }

        &:nth-child(4) {
          animation-delay: 12s; }

        @include media-tablet {
          display: block; }

        &-title {
          @include h48;
          margin-bottom: 1.125rem; }

        &-desc {
          @include fs24; } } } } }
