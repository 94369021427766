.stands-item {
  position: relative;

  display: grid;
  overflow: hidden;

  background-color: white;
  border-radius: 1.25em;

  @include media-tablet-h {
    grid-column: span 2; }

  @include media-desktop {
    grid-column: span 1; }

  &__bg {
    position: relative;

    img {
      position: absolute;

      display: block;
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center; } }

  &__logo {
    position: relative;
    z-index: 2;

    width: 8.75em;
    height: 8.75em;
    padding: 1em 0.75em;
    display: block;

    border: 1px solid $grey-e5;
    border-radius: 0.25em;
    background-color: #ffffff;

    img {
      position: relative;

      display: block;
      width: 100%;
      height: 100%;

      object-position: center;
      object-fit: contain; } }

  &__title {
    @include fs14;

    font-weight: 800; }

  &__desc {
    @include p11;

    @include media-desktop {
      @include p14; } }

  &__content {
    position: relative;

    display: flex;
    flex-direction: column;
    row-gap: 1em;
    padding: 1.5em;

    .btn--favorites-active,
    .btn--favorites-inactive {
      position: absolute;
      right: 1.5rem;

      @include media-tablet {
        right: 2rem; } } }

  &__footer {
    display: flex;
    flex-direction: column;
    row-gap: 0.75em;
    margin-top: auto;
    padding-top: 0.5em; }

  &__contacts {
    grid-area: contacts;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25em 0.75em 0.25em 0.25em;

    border: 1px solid $grey-c0;
    border-radius: 2.0625em;

    &:hover {
      border: 1px solid $color-yellow-new;

      .stands-item__contacts-link {
        color: $color-yellow-new; } }

    &-list {
      display: flex;
      column-gap: 0.25em; }

    &-img {
      overflow: hidden;
      width: 1.5em;
      height: 1.5em;

      border-radius: 50%;
      background-color: $grey-e5;

      &:last-of-type {
        margin-right: 0; }

      img {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;

        border-radius: 50%; } }

    &-link {
      @include fs11;

      color: $grey-88;
      text-decoration: underline; } }

  &__btn {
    min-width: 0 !important;
    width: 100%; }

  &__bookmarks {
    position: absolute;
    top: 1em;
    right: 0.75em;
    z-index: 3;

    display: none;
    width: 2.25em;
    height: 2.25em;

    background-image: url("../images/stands-bookmarks-off.svg");
    background-repeat: no-repeat;
    background-position: center;

    &--active {
      background-image: url("../images/stands-bookmarks-on.svg"); } }

  // general stand

  &--general {
    grid-template-rows: 15em 1fr;

    @include media-tablet {
      grid-column: span 2;
      grid-template-columns: 19.1875em 1fr;
      grid-template-rows: 1fr; }

    @include media-tablet-h {
      grid-column: span 6;
      grid-template-columns: 1fr 44.6875em; }

    @include media-desktop {
      grid-column: span 4; }

    .stands-item {
      &__content {
        margin-top: -4.75em;
        padding-top: 0;

        @include media-tablet {
          margin-top: 0;
          padding: 2em; }

        .btn--favorites {
          &-inactive,
          &-active {
            top: 6.75rem;

            @include media-tablet {
              top: 2rem; } } } }

      &__title {
        @include fs18;

        @include media-tablet {
          @include h24; } }

      &__desc {
        @include media-tablet {
          @include p14; } }

      &__footer {
        @include media-tablet-h {
          flex-direction: row;
          justify-content: space-between; } }

      &__contacts {
        @include media-tablet-h {
          column-gap: 2.5em; }

        &-img {
          @include media-tablet-h {
            width: 2.25em;
            height: 2.25em; } }

        &-list {
          @include media-tablet-h {
            column-gap: 0.5em; } }

        &-link {
          @include media-tablet-h {
            @include fs14; } } }

      &__btn {
        @include media-tablet-h {
          width: auto;
          min-width: 12.625em !important; } } } }

  &--platinum {
    grid-template-rows: 10em 1fr;

    @include media-tablet {
      grid-column: span 2;
      grid-template-columns: 22.125em 1fr;
      grid-template-rows: 1fr; }

    @include media-tablet-h {
      grid-column: span 3;
      grid-template-columns: repeat(2, 1fr); }

    @include media-desktop {
      grid-column: span 2; }

    .stands-item {
      &__content {
        padding-top: 7.25em;

        @include media-tablet {
          padding-top: 1.5em; }

        .btn--favorites {
          &-inactive,
          &-active {
            top: 4.375rem;

            @include media-tablet {
              position: initial;

              margin-left: auto; } } } }

      &__title {
        @include fs16;

        @include media-tablet {
          @include h24; }

        @include media-tablet-h {
          @include fs14; }

        @include media-desktop {
          @include fs18; } }

      &__desc {
        @include media-tablet {
          @include p14; } }

      &__logo {
        position: absolute;
        left: 1.5em;
        bottom: -6.25em;

        @include media-tablet {
          left: 50%;
          bottom: 1.5em;
          transform: translateX(-50%); }

        @include media-tablet-h; } } }

  &--gold {
    grid-template-rows: 7.5em 1fr;

    .stands-item {
      &__content {
        margin-top: -2.5em;
        padding-top: 0;

        .btn--favorites {
          &-inactive,
          &-active {
            top: 6.75rem; } } } } }

  &--silver {
    .stands-item {
      &__content {}
      .btn--favorites {
        &-inactive,
        &-active {
          top: 1.5rem; } } } }

  &--bronze {}

  &--agent {
    grid-column: span 1;
    padding: 1.5em;

    .stands-item {
      &__logo-wrapper {
        width: 5em;
        height: 5em;
        display: block;

        padding: 0;
        border: none;

        border-radius: 50%;
        background-color: $grey-f5;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
          object-position: center; } }

      &__content {
        display: flex;
        flex-direction: column; }

      &__bg {
        display: none; }

      &__title {
        @include fs16;

        margin-top: 1em;

        font-weight: 800; }

      &__desc {
        margin-top: 0.727em;
        margin-bottom: 2.1818em;

        @include media-tablet {
          margin-bottom: 2.318em;
          grid-area: desc; }

        @include media-desktop {
          margin-top: 0.571em;
          margin-bottom: 0.928em; } } } }

  &.swiper-slide {
    height: auto; } }
