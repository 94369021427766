@import "margin";
@import "padding";
@import "col";
@import "row";
@import "flex";
@import "size";
@import "border";
@import "color";
@import "position";
@import "text";
@import "td";

.no-scroll {
    overflow: hidden; }
.tal {
    text-align: left; }
.tac {
    text-align: center; }
.tar {
    text-align: right; }
.pr {
    position: relative; }
