.input-dd {
  position: relative;

  &--close {
    .input-dd__main {
      height: 0;
      padding: 0;
      overflow: hidden;
      visibility: hidden; }

    .input-dd__trigger {
      border-radius: 2rem;
      border: 1px solid $grey-e5; } }

  &__trigger {
    @extend .input[type="text"];

    position: relative;
    z-index: 10;

    display: block;

    // display: flex
    align-items: center;
    height: auto;
    min-height: 2.7143em;
    padding: 0.5714em 0.8571em;
    padding-right: 3em;

    color: $dark-grey;

    background-color: #ffffff;
    cursor: pointer;
    border-bottom: 1px solid #ffffff;
    border-radius: 1rem 1rem 0 0;

    &::after {
      @extend .select__wrapper::after; }

    span {
      color: $grey-88; } }

  &__main {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 5;

    padding: 1.5em;
    max-height: 32em;

    background-color: #ffffff;
    box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.15);
    overflow-y: scroll;
    transition: none;

    label {
      @include fs14;

      display: inline-block;
      padding: 0.5714em 0;

      user-select: none;
      cursor: pointer; }

    input:checked + label {
      color: $red; } }

  &__group {
    margin: 0.5em 0;

    &:first-child {
      margin-top: 0; }

    &:last-child {
      margin-bottom: 0; }

    &-title {
      @include fs14;

      margin-bottom: 0.5714em;

      font-weight: 800; } } }
