.partners {
  padding: 2.5em 0 3.4375em;

  background-color: $grey-f0;

  @include media-tablet {
    padding: 2.5em 0; }

  @include media-desktop-1600 {
    padding: 5.5em 0; }

  &__group {
    display: flex;
    // column-gap: 1.5em
    align-items: center;

    @include media-tablet {
      margin-bottom: 0; }

    @include media-desktop {
      margin-right: 0em; }
    &-desktop {
      display: none;
      @include media-desktop-1600 {
        display: flex;
        column-gap: 0;
        row-gap: 0;
        justify-content: space-between; } }
    &-mobile {
      display: flex;
      flex-direction: column;
      @include media-tablet-h {
        justify-content: space-between;
        align-items: center;
        flex-direction: row; }
      @include media-desktop-1600 {
        display: none; } }
    &--one-item {
      display: flex;
      column-gap: 0;

      .partners {
        &__title {
          margin-bottom: 1em;
          @include media-tablet {
            width: 10em;
            margin-bottom: 0; }
          @include media-tablet-h {
            width: auto;
            margin-right: 2.181em; } } } }

    &--many-items {
      display: grid;

      @include media-tablet-h {
        grid-template-columns: auto 1fr;
        column-gap: 1.5em; }

      .partners {
        &__title {
          text-align: center;
          padding-bottom: 1em;

          @include media-tablet {
            text-align: left; }

          @include media-tablet-h {
            padding-bottom: 0; }

          br {
            display: none;

            @include media-tablet-h {
              display: inline; } } }

        &__list {
          @include media-desktop {
            display: flex; } } } }

    &--org {
      grid-row: 1;
      margin-bottom: 1em;
      flex-direction: column;
      justify-content: center;

      @include media-tablet {
        flex-direction: initial;
        justify-content: initial;
        grid-column: 1; }
      @include media-tablet-h {
        margin-bottom: 0; }
      .partners__title {
        @include media-tablet {
          margin-right: 2em; }
        @include media-tablet-h {
          margin-right: 2.181em; } } }

    &--sup {
      grid-row: 1;
      margin-bottom: 1em;
      flex-direction: column;
      @include media-tablet {
        grid-column: 1;
        flex-direction: row; }
      @include media-desktop-1600 {
        margin-bottom: 0; } }

    &--support {
      display: flex;
      margin-bottom: 0;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;

      @include media-tablet {
        flex-direction: initial;
        flex-wrap: initial;
        justify-content: initial;
        grid-column: 1 / span 2;
        grid-row: 2; }
      @include media-tablet-h {
        grid-row: 3; } }

    &--sponsor {
      grid-row: 2;

      @include media-tablet {
        grid-row: 1;
        grid-column: 2;
        justify-self: end; } } }

  &__title {
    @include fs11;
    // grid-column: 1

    color: $grey;
    line-height: 1;
    @include media-tablet {
      width: 10em; }
    @include media-tablet-h {
      width: initial; }
    &--sup {
      @include media-tablet-h {
        max-width: 3.625em; } } }

  &__list {
    @include lreset;
    display: flex;
    // grid-column: 2)
    // grid-auto-columns: 5.625em
    // grid-auto-rows: auto
    // grid-auto-flow: column dense
    flex-wrap: wrap;
    justify-content: center;
    @include media-tablet {
      column-gap: 2.5em; } }

  &__item {
    width: 5.625em;
    height: 3.375em;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      transform: translateZ(0); }
    &-1 {
      @include media-tablet {
        margin-left: 2em;
        margin-right: 2em; } }
    &-3 {
      img {
        transform: none; } }
    &-4 {
      margin-left: 0.5em;
      margin-right: 0.5em;
      @include media-tablet {
        margin-left: 0;
        margin-right: 0; } }
    &-5 {
      margin-left: 0.5em;
      margin-right: 0.5em;
      margin-top: 0.5em;
      @include media-tablet {
        margin-left: 0;
        margin-right: 0; } } } }
