.about {
  position: relative;
  padding-top: 5.1875em;
  background-color: $grey-f0;
  overflow: hidden;
  z-index: 1;

  @include media-tablet-h {
    padding-top: 11.4375em; }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    background: $color-yellow-2;
    width: 100%;
    height: 40em;
    bottom: 0;
    border-top-left-radius: 9em 1em;
    border-top-right-radius: 42em 29em;

    @include media-tablet {
      border-radius: 0;
      background: url(@public/images/about-bottom-bg.svg) no-repeat center;
      bottom: 0;
      right: 50%;
      margin-right: -5em;
      width: 65.8125em;
      height: 46.5em;
      margin-right: -11em; }

    @include media-tablet-h {
      background: url(@public/images/about-bottom-bg-1.svg) no-repeat center;
      margin-right: -21em;
      width: 74.5625em;
      height: 44.6875em; }

    @include media-desktop {
      margin-right: -22em; }

    @include media-desktop-1440 {
      margin-right: -17em; }

    @include media-desktop-1600 {
      margin-right: -15em; } }

  &__header {
    display: flex;
    margin-bottom: 3em;
    flex-direction: column;

    @include media-tablet {
      flex-direction: row;
      margin-bottom: 5em;
      justify-content: space-between; }

    &-desc {
      @include fs14;
      max-width: 26em;
      width: 100%;
      color: $color-black;

      @include media-tablet {
        @include fs18;
        max-width: 17em; }

      @include media-tablet-h {
        max-width: 21em;
        @include fs24; }

      @include media-desktop {
        max-width: 25em; } } }

  &__title {
    @include h36;
    max-width: 8em;
    width: 100%;
    color: $color-black;
    margin-bottom: 0.5em;

    @include media-tablet {
      @include h48;
      margin-bottom: 0; }

    @include media-desktop {
      max-width: initial; } }

  &__slider {
    margin-bottom: 6.125em;

    @include media-tablet {
      margin-bottom: 8.125em; }

    &-slide {
      width: 16.25em;

      @include media-tablet {
        width: 34em; } }

    .swiper-slide {
      width: initial;
      min-height: 100%;
      height: initial; }

    .swiper-container {
      overflow: initial; }

    .swiper-pagination {
      bottom: -3.6em !important; }

    .swiper-pagination-bullet {
      background: $color-black;
      opacity: 1;
      width: 0.625em;
      height: 0.625em;
      margin-right: 0.75em; }

    .swiper-pagination-bullet-active {
      background: #fff;
      width: 0.625em;
      height: 0.625em;
      margin-right: 0.75em;
      opacity: 1;
      width: 0.625em;
      height: 0.625em;
      margin-right: 0.75em; }

    &__item {
      background: #fff;
      width: 16.25em;
      box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.15);
      border-radius: 1.5em;
      padding: 1.5em;
      cursor: pointer;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include media-tablet {
        padding: 2.5em; }

      &:hover {
        .about__slider-title {
          color: $color-yellow-2; } }

      @include media-tablet {
        width: 34em; } }

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 1.5em; }

    &-date {
      @include fs16;
      color: $color-black;

      @include media-tablet {
        @include fs18; } }

    &-time {
      font-size: 0.75em;
      font-weight: bold;
      color: $color-black;
      position: relative;
      top: 0.15em;

      @include media-tablet {
        position: initial;
        top: 0;
        @include h18; }

      span {
        font-size: 0.85em;
        position: relative;
        top: -0.5em;

        @include media-tablet {
          top: -0.4em; } } }

    hr {
      height: 1.4375em;
      width: 1px;
      margin: 0;
      margin-left: 0.3em;
      margin-right: 0.3em;

      border: none;
      background-color: $color-black;

      @include media-tablet {
        margin-left: 1em;
        margin-right: 1em; } }

    &-title {
      @include h18;
      color: $color-black;
      margin-bottom: 0.5em;
      transition: all 0.2s linear;

      @include media-tablet {
        margin-bottom: 0.2em;
        @include h24; } }

    &-text {
      @include fs14;
      color: $color-black;

      @include media-tablet {
        @include fs18;
        margin-bottom: 1em; } }

    &-avatars {
      display: none;

      @include media-tablet {
        display: flex; }

      &-avatar {
        height: 2.5em;
        width: 2.5em;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 1em; } } } }
