.main-nav {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;

  background-color: black;

  .cont {
    height: 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-tablet {
      height: 3.625em; }

    @include media-desktop {
      position: relative; } }

  &__wrapper {
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);

    @include media-tablet {
      position: relative;
      transform: translateX(0); } }

  &__left {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    @include media-tablet {
      column-gap: 1.5em; }

    hr {
      @include reset;

      display: none;
      width: 0.0625em;
      height: 100%;

      border: none;
      background-color: $grey-88;

      @include media-tablet {
        display: block; } } }

  &__logo {
    @include media-desktop {
      flex-grow: 0; }

    &-wrapper {
      display: grid;

      grid-template-columns: repeat(3, max-content);
      column-gap: 1em;
      align-items: center; }

    &-plus {
      @include fs18;

      color: #ffffff;
      display: none;
      @include media-tablet-h {
        display: block; } }

    &-link {
      @include fs18;

      color: #ffffff;
      font-weight: 800;
      display: none;
      @include media-tablet-h {
        display: block; } }

    img {
      width: 2.5em;
      height: 1.25em;

      @include media-tablet {
        width: 3.562em;
        height: 1.75em; } } }

  &__menu {
    @include lreset;

    display: none;

    @include media-tablet {
      display: grid;
      column-gap: 1.5em;
      grid-auto-flow: column; }

    // @include media-desktop
    //   margin-left: 4em

    &-item {
      a {
        @include fs11;

        color: white;

        @include media-tablet;

        @include media-tablet-h {
          @include fs14; }

        @include media-desktop {
          @include fs16; } } }

    &-hamburger {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5em;
      height: 1.5em;
      margin-left: auto;

      svg {
        width: 1em;
        height: 0.75em; }

      &::before,
      &::after {
        content: "";

        position: absolute; }

      @include media-tablet {
        display: none; } } }

  &__counter {
    @include fs14;

    display: none;
    flex-grow: 1;

    color: white;
    text-align: center;

    opacity: 0.7;

    @include media-desktop {
      display: block; }

    span {
      font-weight: 800; } }

  &__user-nav {
    @include reset;

    display: none;

    @include media-tablet {
      display: flex;
      align-items: center; }

    &--unlogged {
      display: flex; }

    &-item {
      margin-right: 0.5em;

      &:last-child {
        margin-right: 0; }

      a {
        @include fs16;

        color: white;

        @include media-desktop {
          @include fs14; } }

      &--meetings {
        display: none;

        @include media-desktop {
          display: block;
          margin-right: 2.5em; }

        a {
          display: flex;
          align-items: stretch;

          border: 1px solid $color-yellow-new;
          border-radius: 3.5rem;
          overflow: hidden;

          span {
            &:nth-child(1) {
              padding: 0.5625rem 1.8125rem; }

            &:nth-child(2) {
              display: flex;
              align-items: center;
              padding: 0 0.5rem 0 0.25rem;

              font-size: 0.7857em;
              font-weight: 800;

              background-color: $blue; } } } }

      &--msgs {
        display: none;

        @include media-desktop {
          display: block; }

        a {
          display: flex;
          align-items: center;
          justify-content: center; }

        span {
          @include fs11;

          margin-left: 0.571em;
          padding: 0.1818em 0.3636em;

          font-weight: 800;

          border-radius: 0.3636em;
          background-color: $blue; } }

      &--name {
        a {
          @include fs16;

          position: relative;

          padding-right: 2.1875em;

          font-weight: 800;

          @include media-desktop {
            @include fs14;

            padding-right: 1.4375em; }

          &::after {
            content: "";

            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            width: 1.5625em;
            height: 1.5625em;

            background-image: url("../images/nav-user.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            @include media-desktop {
              width: 1.0625em;
              height: 1.0625em; } } } }

      &--reg {
        a {
          @include fs14;
          color: #000;
          font-weight: 800;
          height: 2.25em;
          background: $color-yellow-new;
          border-radius: 3.6875em;
          padding: 0.5625em 1.5em;

          @include media-tablet {
            @include fs16; }

          @include media-desktop {
            @include fs14; } } }
      &--login {
        list-style-type: none;
        a {
          @include fs14;
          height: 2.25em;
          color: $grey-89;
          background: #fff;
          border: 1px solid $grey-89;
          border-radius: 3.6875em;
          padding: 0.5625em 1.5em;

          @include media-tablet {
            @include fs16; }

          @include media-desktop {
            @include fs14; } } } } }

  &__dropdown {
    position: fixed;
    right: -22.75em;
    top: 0;
    bottom: 0;
    z-index: 9999;

    width: 17.5em;
    padding: 1.5em;

    background-color: white;

    box-shadow: 0 0.25em 2.5em rgba(0, 0, 0, 0.15);
    border-radius: 0.75em 0 0 0.75em;
    visibility: hidden;
    opacity: 0;

    transition: opacity 0.3s ease-out, transform 0.3s ease-out, visibility 0.3s ease-out;

    @include media-tablet {
 }      // top: 3.625em

    @include media-desktop {
      position: absolute;

      bottom: auto;
      top: 3.625em;
      right: -27.9875em;

      width: 25.375em;

      border-radius: 0.75em; }

    &--open {
      // display: none
      visibility: visible;
      opacity: 1;
      transform: translateX(-130%); }

    hr {
      height: 0.0625em;
      width: 100%;
      margin-top: 1em;
      margin-bottom: 1em;

      border: none;
      background-color: $grey-c0; }

    &-close {
      position: absolute;
      top: 2.375em;
      right: 1.625em;

      width: 1em;
      height: 1em;
      transform: rotate(45deg);

      cursor: pointer;

      @include media-desktop {
        top: 1.5em;
        right: 1.5em; }

      &::before,
      &::after {
        content: "";

        position: absolute;

        background-color: $dark-grey; }

      &::before {
        top: 50%;
        transform: translateY(-50%);

        width: 1em;
        height: 0.125em; }

      &::after {
        left: 50%;
        transform: translateX(-50%);

        height: 1em;
        width: 0.125em; } }

    &-name {
      @include reset;
      @include fs16;

      width: 12em;

      font-weight: 800;

      @include media-desktop {
        width: 100%;
        padding-right: 2em; } }

    &-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &>.main-nav__dropdown-item {
        &:last-child {
          margin-bottom: 0; } } }

    &-sublist {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-btn {
        display: none; }

      &--closed {
        border: 1px dashed $grey-c0;
        padding: 0.75em 5.125em 0.75em 0.75em;
        margin-bottom: 0.75em;
        width: 100%;

        .main-nav__dropdown-item {
          color: $grey-c0;

          &:last-of-type {
            margin-bottom: 0; }

          &--msg {
            span {
              display: none; } } }

        .main-nav__dropdown-sublist-btn {
          position: absolute;
          top: 50%;
          right: 1.5em;
          transform: translateY(-50%);

          display: block;
          width: 2.125em;
          height: 2.125em;

          border-radius: 0.25em;
          border: 1px solid $grey-c0;

          background-image: url("../images/lock.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 1em 1.125em;

          @include media-desktop {
            width: auto;
            min-width: 8.125em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 2.125em;

            background-position: right 0.5625em center; }

          &::after {
            content: "";

            position: absolute;
            top: 0;
            bottom: 0;
            right: 2em;

            width: 0.0625em;

            background-color: $grey-c0; }

          span {
            display: none;

            @include media-desktop {
              @include fs14;

              display: block;

              color: $grey-88; } } } }

      &--closed-2 {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C0C0C0FF' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        padding: 0.75em 5.125em 0.75em 0.75em;
        margin-bottom: 0.75em;
        width: 100%;

        .main-nav__dropdown-item {
          color: $grey-c0;

          &:last-of-type {
            margin-bottom: 0; }

          &--msg {
            span {
              display: none; } } }

        .main-nav__dropdown-sublist-btn {
          display: block;
          height: 2.125em;
          width: auto;
          min-width: 8.125em;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.857em;

          text-align: center;

          border-radius: 0.25em;
          border: 1px solid $grey-c0;

          @include media-desktop {
            position: absolute;
            top: 50%;
            right: 1.5em;
            transform: translateY(-50%);

            margin-top: 0; }

          span {
            @include fs14;

            color: $grey-88; } } } }

    &-item {
      @include fs14;

      margin-bottom: 0.857em;

      color: $dark-grey;

      &-counter {
        padding: 0.1818em 0.3636em;
        margin-left: 0.875rem;

        color: #ffffff;
        font-size: 0.7857em;
        font-weight: 800;

        border-radius: 2rem;
        background-color: $blue; }

      &--msg {
        span {
          @include fs11;

          margin-left: 0.571em;
          padding: 0.1818em 0.3636em;

          font-weight: 800;
          color: white;

          border-radius: 0.3636em;
          background-color: $blue; } }

      &--networking {
        position: relative;

        padding-left: 1rem;

        color: $grey-88;
        font-weight: 700;

        &::before {
          content: "";

          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          width: 0.5rem;
          height: 0.5rem;

          border-radius: 50%;
          background-color: $grey-e5; }

        &-active {
          &::before {
            background-color: $green; } } }

      &--closed {
        width: 2.125rem;
        height: 2.125rem;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        border: 1px solid $grey-88; } }

    &-company {
      &-title {
        @include reset;
        @include fs14;

        margin-bottom: 0.571em;

        color: $grey-c0;

        a {
          color: $dark-grey; } }

      &-logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center; }

      &-item {
        width: 7.5em;
        height: 5em;
        padding: 0.625em;
        margin-right: 1em;

        img {
          width: 100%;
          height: 100%;
          display: block;

          object-fit: contain;
          object-position: center; } }

      &-name {
        @include fs14;

        display: none;

        font-weight: 800;

        @include media-desktop {
          display: block; } }

      &-btn {
        @include fs14;

        display: inline-block;
        margin: 0.3em 0 0.3em;
        padding: 0.571em 1.071em;

        color: $dark-grey;

        border: 1px solid $dark-grey;
        border-radius: 0.286em; }

      &--added {
        .main-nav__dropdown-company {
          &-title {
            display: flex;
            justify-content: space-between;

            a {
              color: $blue;
              text-decoration: underline;

              &:hover {
                text-decoration: none; } } }

          &-logos {
            color: $dark-grey; } } }

      &--notAdded {
        .main-nav__dropdown-company {
          &-item {
            display: none;
            position: relative;
            margin-right: 0.375em;

            @include media-desktop {
              display: block; }

            &::before {
              content: "";

              position: absolute;
              left: 0.625em;
              top: 0.625em;
              right: 0.625em;
              bottom: 0.625em;

              border: 1px dashed $grey-c0; } } } } }

    &-exit {
      @include fs14;

      color: $blue; } } }
