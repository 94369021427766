.radio {
  display: flex;

  background-color: $grey-f5;
  border-radius: 2rem;

  user-select: none;

  @include max-w(500px) {
    flex-direction: column; }

  label {
    @include fs14;

    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.7143em;

    border-radius: 0.2857em;
    border: 1px solid $grey-f5;
    cursor: pointer; }

  input:checked + label {
    background-color: #ffffff;
    border-radius: 2rem;
    border: 1px solid $blue; } }
