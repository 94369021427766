.dynamic {
  background-color: $grey-e5;
  @include media-desktop {
    padding: 5rem 0; }

  &__block {
    display: grid;
    grid-template-rows: 33.75em 1fr;

    color: #ffffff;

    background-color: $color-black;
    border-radius: 1.25rem;
    overflow: hidden;

    @include media-tablet {
      grid-template-rows: 28.25em 1fr;
      border-radius: 0;
      border-bottom-left-radius: 1.25em;
      border-bottom-right-radius: 1.25em; }

    @include media-tablet-h {
      border-radius: 1.25rem;
      grid-template-columns: 1fr 18.875em;
      grid-template-rows: auto;
      height: 30.3125rem; }

    @include media-desktop {
      height: 36.8125rem; }

    @include media-desktop-1440 {
      height: 41.625rem; } }

  &__cont {
    @include max-w(1280px) {
      padding: 0;
      margin: 0; } }

  &__main {
    position: relative;

    padding: 1.5em;
    overflow: hidden;

    // @include media-tablet
    //   height: 28.25rem

    @include media-desktop {
      padding: 2.5em; }

    &-content {
      display: flex;
      flex-direction: column;
      height: 100%; }

    &-header {
      position: relative;
      z-index: 2;

      @include media-tablet {
        display: flex;
        justify-content: space-between; } } }

  &__video {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    &::before {
      content: "";

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;

      background-color: rgba(#000000, 0.5); }

    iframe,
    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
      filter: blur(1em); } }

  &__status {
    @include fs18;

    position: relative;

    padding-left: 1.6667em;

    &::before {
      content: "";

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      width: 1em;
      height: 1em;

      border: 1px solid #ffffff;
      border-radius: 50%; }

    &--online {
      &::before {
        background-color: $red;
        border: 1px solid $red; } } }

  &__date {
    @include fs18;

    margin-top: 1.3333em;

    @include media-tablet {
      margin-top: 0; }

    b {
      &:last-child {
        display: inline-block;
        min-width: 5.9375rem; } } }

  &__active {
    position: relative;
    z-index: 2;

    display: grid;
    row-gap: 1em;
    margin-top: auto;

    &-date {
      @include h24;

      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 1.5833em;

      @include media-desktop {
        @include h36; } }

    &-day {
      position: relative;

      font-weight: 400;

      &::before {
        content: "";

        position: absolute;
        left: -0.79em;
        top: 50%;
        transform: translateY(-50%);

        width: 1px;
        height: 0.75em;

        background-color: #ffffff; } }

    &-time {}

    &-timezone {
      position: relative;
      top: -0.5em;
      left: 0.3em;
      font-size: 0.75em; }

    &-desc {
      @include fs18;

      @include media-desktop {
        @include h24;

        font-weight: 400; } }

    &-btn {
      height: 2.6em;
      width: 9.375em;
      margin-top: 1.5rem;

      font-weight: 800;

      @include media-tablet {
        justify-self: start; } } }

  &__schedule {
    position: relative;

    padding: 1.5em;
    display: grid;
    row-gap: 2.5em;
    max-height: 33.75em;
    // overflow: hidden
    overflow-y: scroll;
    grid-auto-rows: max-content;

    @include media-tablet {
      max-height: 39.25em; }

    @include media-tablet-h {
      max-height: 30.3125em; }

    @include media-desktop {
      max-height: 100%;
      padding: 2.5em 2em;
      height: 100%; }

    &::-webkit-scrollbar {
      width: 0.25rem;
      margin-right: 5px; }

    &::-webkit-scrollbar-track {
      background-color: rgba(#ffffff, 0.17); }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(#ffffff, 0.33);
      border-radius: 1.25rem; }
    // &::after
    //   content: ""

    //   position: absolute
    //   bottom: 0
    //   left: 0
    //   right: 0

    //   height: 12.75em

    //   background: linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, $dark-grey 100%);

    &-header {
      @include fs18;
      @include reset;

      color: #fff; } }

  &__list {
    @include lreset;

    display: grid;
    row-gap: 3em;

    li {
      position: relative;

      &::after {
        content: "";

        position: absolute;
        left: 0;
        right: 0;
        bottom: -1.5rem;

        height: 1px;

        background-color: rgba(#ffffff, 0.33); }

      &:last-child {
        &::after {
          display: none; } } } }

  &__item {

    display: grid;
    row-gap: 0.25em;

    color: #ffffff;

    &-date {
      @include fs18;

      display: grid;
      column-gap: 1.5556em;
      grid-template-columns: auto 1fr; }

    &-time {
      font-weight: 800; }

    &-timezone {
      position: relative;
      top: -0.4em;

      font-size: 0.7778em; }

    &-desc {
      @include p14; }

    &-company {
      @include fs14;

      margin-top: 0.5714em;

      color: $grey-88; }

    &-day {
      position: relative;
      color: $color-yellow-new;
      &::before {
        content: "";

        position: absolute;
        left: -0.777em;
        top: 50%;
        transform: translateY(-50%);

        width: 1px;
        height: 0.75em;

        background-color: $color-yellow-new; } } } }
