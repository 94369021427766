.usernav {
  &__list {
    @include lreset;

    display: grid;
    grid-row-gap: 0.75em;

    &--closed {
      display: inline-grid;
      margin-top: 0.75em;
      padding: 0.75em 1.5em 0.75em 0.75em;

      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C0C0C0FF' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

      .usernav {
        &__item {
          &-link {
            grid-template-columns: 1fr;
            color: $grey-c0;

            &--soon {
              padding: 0.5em 0;
              width: 100%;

              color: $grey-88;
              text-align: center;

              border-radius: 4px;
              border: 1px solid $grey-88; }

            &--closed {
              width: 2.125rem;
              height: 2.125rem;
              display: flex;
              align-items: center;
              justify-content: center;

              border-radius: 50%;
              border: 1px solid $grey-88; } } } } } }


  &__item {
    &--active {
      a {
        color: $color-black; } }

    &-link {
      @include fs14;

      display: inline-flex;
      column-gap: 0.5714em;
      align-items: center;

      color: $dark-grey;

      &--networking {
        position: relative;

        padding-left: 1rem;

        color: $grey-88;
        font-weight: 700;

        &::before {
          content: "";

          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          width: 0.5rem;
          height: 0.5rem;

          border-radius: 50%;
          background-color: $blue; }

        &-active {
          &::before {
            background-color: $blue; } }

        &.usernav__item-link--networking-active {
          color: $dark-grey;

          &::before {
            background-color: $blue; } } } }

    &-counter {
      @include fs11;

      display: flex;
      align-items: center;
      height: 1.7273em;
      padding: 0 0.3636em;

      color: #fff;
      font-weight: 800;

      border: 1px solid $grey-c0;
      border-radius: 2rem;

      &--active {
        color: #ffffff;

        border-color: $blue;
        background-color: $blue; } } } }
